<template>
  <div>
    <LandingPage/>
  </div>
</template>

<script>
 import LandingPage from '@/components/page/LandingPage/LandingPage.vue'
export default {
  name: 'LandingVew',
  components: {
    LandingPage,
  }
}
</script>