<template>
    <body style="background: grey;">
    <div>
        <HeaDer/>
    </div>
    <section class="Policyinner">
        <article class="pageHader">
            <div>개인정보 처리방침</div>
        </article>
        <br>
        <article>
            <div class="Main">제 1조(총칙)</div><br>
  
            <div class="Sub">1. 목적</div><br>
            <div>
                (주)일의밀도(이하”회사”라고 함)은 회원의 정보를 소중하게 생각하고 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, 개인정보보호법을 포함한 관계 법령과 정부의 가이드라인을 준수합니다. <br>
                회사는 “개인정보처리방침”을 제정하고 이를 준수하여 회원의 개인정보의 이용 및 보호에 대한 조치를 알리며, 관련 법규를 준수하고 있습니다. <br>
                회사가 개인정보를 수집하는 목적은 회원의 신분과 서비스 이용 의사를 확인하여 최적화. 맞춤화 된 서비스를 제공하기 위함입니다.
            </div><br>
  
            <div class="Sub">2. 개인정보의 수집.이용</div><br>
            <div>
                회사는 회원의 개인정보를 필요한 시점에 최소한으로 수집하고, 수집한 정보는 사전 고지한 범위 내에서만 사용하며 사전 동의 없이 목적과 범위를 초과하여 이용하거나 외부에 공개하지 않습니다. <br>
                다만, 법령의 규정에 의거하거나 수사기관 및 행정기관이 수사 또는 조사의 목적으로 법령에 정해진 절차와 방법에 따라 요청한 경우에는 내부 절차를 거쳐 최소한의 정보가 동의 없이 제공될 수 있습니다.  <br>
            </div> <br>
  
            <div class="Sub">3. 개인정보처리방침의 효력 및 변경</div><br>
            <div>
                회사의 “개인정보처리방침”은 관련 법률 및 지침의 변경 또는 내부 운영 방침의 변경 등으로 인하여 개정될 수 있습니다. <br>
                “개인정보처리방침”이 변경될 경우 변경사항은 제11조에서 명시한 고지의 의무에 따라 공지사항 또는 이메일 등의 기타의 방법을 통해 공지해 드립니다. <br>
                변경사항은 홈페이지를 방문하여 확인하실 수 있으며, 변경내용에 의문이 있으신 경우에는 언제라도 회사에 문의를 하실 수 있습니다. <br>
                개인정보처리방침과 이용약관의 개인정보 관련 항목 중, 이용약관의 내용이 우선하여 적용됩니다. 
            </div><br>
        </article>
        <article>
  
            <div class="Main">제 2조 (개인정보의 수집. 이용 및 보유)</div><br>
  
            <div class="Sub">1. 개인정보의 수집 방법</div><br>
            <div>
                회사는 다음과 같은 방법으로 개인정보를 수집할 수 있습니다. <br><br>
  
                - 회원가입 및 서비스 이용 과정에서 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다. <br>
                -홈페이지, 모바일 애플리케이션, 모바일 웹페이지, 서면, 팩스, 전화, 고객센터 문의하기, 이벤트 응모 <br>
                -생성 정보 수집툴을 통한 자동 수집  <br>
                -회사와 제휴한 외부 기업이나 단체로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는 제휴사에서 이용자에게 개인정보 제공 동의를 받은 후 수집합니다. 
            </div><br>
  
            <div class="Sub">2. 개인정보의 수집. 이용목적</div><br>
            <div>
                회사는 개인정보를 다음의 목적을 위해. 수집 이용합니다. 수집한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구합니다.
            </div>
            <div>1) 플레폼 회원가입 및 서비스 이용 관리</div><br>
            <div>
                회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별. 인증, 회원자격 유지. 관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 만 19세 미만의 기업설립 요청시 법정대리인의 동의 여부 확인, 서비스 이용 관련 각종 고지. 통지. 고충처리. 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다. 
            </div><br>
            <div>2) 민원 사무 처리</div><br>
            <div>민원인은 신원확인, 민원사항 확인, 사실조사를 위한 연락. 통지, 처리결과 통보 등을 목적으로 개인정보를 처리 합니다. </div><br>
            <div>3) 재화 또는 서비스 제공</div><br>
            <div>물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제.정산, 채권추심 등을 목적으로 개인정보를 처리합니다. </div><br>
            <div>4) 마케팅 광고에의 활용</div><br>
            <div>신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여 기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고게재, 서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.</div>
            <br>
            <div class="Sub">3. 개인정보의 수집 항목 및 보유</div><br>
            <div>
                1) 회사는 텍스트, 이미지, 음성 등의 개인정보를 수집 및 이용할 수 있습니다. 회사가 수집하는 정보 및 보유기간에 대한 상세한 내용은 아래 표와 같습니다. <br>
                2) 수집한 개인정보는 법령에 따른 개인정보 이용·보유기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 이용·보유기간 내에서 처리 및 보관합니다.<br>
                3) 회사는 전자상거래 등에서의 소비자보호에 관한 법률, 국세기본법, 전자금융거래법 등 관련 법령에 따라 주민등록번호 및 은행 계좌번호의 수집∙보관이 불가피한 경우에는 회원에게 사전 고지하여 정보를 수집할 수 있습니다.<br>
                4) 회사는 회원의 기본적 인권을 침해할 우려가 있는 민감한 정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향, 범죄기록, 건강 상태 등)는 회원의 동의 또는 법령의 규정에 의한 경우 이외에는 수집하지 않습니다. <br>
                5) 회사가 제공하는 서비스 이용과정에서 회원본인이 자발적으로 공개한 개인정보 및 게시물과 관련된 권리와 책임은 작성자 본인에게 있습니다. <br>
                6) 자발적으로 공개된 정보는 보호받기 어려우며 타인에 의해 무단으로 수집되거나 처리될 수 있으며, 이로 인해 발생한 손실이나 문제는 전적으로 본인에게 있음을 유념하시기 바랍니다. 서비스 이용과정에서 개인에 관한 정보는 공개되 않도록 심혈을 기울여 주시기 바랍니다. <br>
                7) 회사는 다음 목적 이외로는 개인정보를 이용하지 않으며, 회사에서 제공하는 서비스의 목적이 변경되는 경우 별도의 동의를 받는 등 필요한 조치를 합니다. <br>
                8) 일의 밀도를 이용하는 서비스의 형태에 따라 다음과 같은 개인정보를 수집 및 이용. 제공. 파기 합니다. <br>
                9) 본 회사는 이용자의 휴대전화번호, 전화번호 목록, 사진, 비디오, 파일을 수집하며 서비스 이용과 고객 센터 이용 목적에 사용하고 있습니다.
            </div><br>
            <table>
                <tr>
                    <th>수집방법</th>
                    <th>수집항목</th>
                    <th>수집 및 이용목적</th>
                    <th>보유 및 이용기간</th>
                </tr>
                <!-- <tr v-for="item in items" :key="item.title">
                    <td><span v-html="item.Collectionmethod"></span></td>
                    <td><span v-html="item.Collectionitems"></span></td>
                    <td><span v-html="item.purposeOfUse"></span></td>
                    <td><span v-html="item.periodOfUse"></span></td>
                </tr> -->
                <tr>
                    <td>회원가입(필수)</td>
                    <td>이름(실명), 이메일주소</td>
                    <td>서비스 이용 및 상담을 위한 이용자 식별</td>
                    <td rowspan="4">회원에서 탈퇴한 후 회원 재가입, 임의해지 등을 반복적으로 행하여 회사가 제공하는 할인 쿠폰, 이벤트 혜택등으로 경제상의 이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 편법과 불법 행위를 하는 회원을 차단하고자 회원 탈퇴후 1개월 동안 보관합니다.</td>
                </tr>
                <tr>
                    <td>회원가입(선택)</td>
                    <td>카카오톡, 네이버, 구글</td>
                    <td>SNS 계정을 통한 간편 가입시</td>
                </tr>
                <tr>
                    <td> </td>
                    <td>프로필사진, 생년월일, 성별, 거주지, 소속기업, 경력사항</td>
                    <td>회원경력 기록 특성화, 포트폴리오 활성화 맞춤형 콘텐츠 및 상품추천 등 마케팅 활용</td>
                </tr>
                <tr>
                    <td>기업등록 및 전환</td>
                    <td>이름, 아이디(이메일), 비밀번호, 휴대전화번호, 지역, 회사정보(사업자번호, 지역, 전화번화, FAX)</td>
                    <td>기업체 본 확인, 회원관리, 기업 포트폴리오 활성화, 맞춤형 콘텐츠 및 상품 추천등 마케팅 활용 </td>
                </tr>
                <tr>
                    <td>고객센터 서비스 제공</td>
                    <td>이름, 아이디, 휴대전화번호, 업체명, 업체주소, 사업자번호등</td>
                    <td>일의밀도 고객 문의 및 서비스 이용 상담</td>
                    <td rowspan="3">목적 달성후 파기 합니다. 단, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령의 규정에 따라 거래관계 확인을 위해 개인정보를 일정 기간 보유 할수 있습니다. </td>
                </tr>
                <tr>
                    <td>이벤트 참여</td>
                    <td>이름, 아이디, 휴대전화번호, 기업정보, 주소등 </td>
                    <td>이벤트 참여자 식별 및 당첨자 확인</td>
                </tr>
                <tr>
                    <td>이벤트 당첨</td>
                    <td>이름, 아이디, 휴대전화번호, 기업정보, 주소등 </td>
                    <td>이벤트당첨에 따른 경품 전달 </td>
                </tr>
                <tr>
                    <td>서비스 이용</td>
                    <td>휴대전화번호, 이용자의 전화번호 목록, 사용자 사진, 비디오, 파일 - 각 프로젝트 진행 기본 정보(수량, 기간.스케쥴, 인원정보등)</td>
                    <td>기업체 본 확인, 회원관리, 기업 포트폴리오 활성화, 맞춤형 콘텐츠 및 상품 추천등 마케팅 활용</td>
                    <td>회원에서 탈퇴한 후 회원 재가입, 임의해지 등을 반복적으로 행하여 회사가 제공하는 할인 쿠폰, 이벤트 혜택등으로 경제상의 이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 편법과 불법 행위를 하는 회원을 차단하고자 회원 탈퇴후 1개월 동안 보관합니다. </td>
                </tr>
                <tr>
                    <td>개인 서비스 이용</td>
                    <td>전화번호부, 사진첩</td>
                    <td>이용자의 친구 추천 및 초대를 위한 수집, 이용자의 포트폴리오 업로드를 위한 사진첩 수집</td>
                    <td>회원 탈퇴 후 1개월 동안 보관합니다.</td>
                </tr>
            </table><br>

            <div class="Sub">4. 전화번호부 </div><br>
            <div>1. 회사는 이용자가 공첵 어플을 사용하는 동안 이용자의 전화번호부를 수집합니다다. </div>
            <div>2. 어플의 원할한 사용을 위해 이용자의 특별한 요청이 없을 시에는 계속 보관합니다. </div>
            <div>3. 이용자가 회원 탈퇴 시에는 1개월 후에 모든 전화번호부 정보를 삭제합니다. </div>
            <div>4. 수집한 전화번호는 해당 이용자의 친구 목록으로 사용하게 됩니다. </div>
            <div>5. 수집한 전화번호는 해당 사용자에게만 표시됩니다. </div><br>

            <div class="Sub">5. 이미지 </div><br>
            <div>1. 회사는 이용자가 공첵 어플을 사용하는 동안 업로드한 사진을 저장합니다. </div>
            <div>2. 어플의 원할한 사용을 위해 이용자의 특별한 요청이 없을 시에는 계속 보관합니다. </div>
            <div>3. 이용자가 회원 탈퇴 시에는 1개월 후에 모든 이미지 정보를 삭제합니다. </div>
            <div>4. 업로드한 사진은 이용자가 기록용으로 저장한 이용자의 프로젝트 이미지로 사용하게 됩니다. </div>
            <div>5. 업로드한 이미지는 이용자의 프로젝트에 접근 가능한 이용자에게만 제공합니다. </div><br>

            <div class="Sub">6. 기타 </div><br>
            <div>1) 회사는  만 14세 이상의 경우에 회원가입을 가능하도록 합니다.</div><br>
            <div>
                2) 회사는 웹 상에 공개 목적이나 대상의 제한 없이 합법적으로 공개된 정보 및 이용자의 서비스 이용기록(로그)과 메타데이터와 같은 정보를 수집하여 이용자에게 보다 좋은 서비스를 제공할 수 있도록 분석 및 활용하고 있습니다. 구체적으로, 이용자 관심에 맞춘 서비스를 우선적으로 제공하고 마케팅적으로 이용자의 관심분야에 맞는 광고를 제공하거나 이벤트 참여기회를 제공할 수 있습니다. 이렇게 수집된 정보는 회사 외 제3자에게 제공하거나 공유 하지 않습니다. <br>
            </div>
            <div>
                3)광고성 정보 수신에 동의하신 회원은 회사의 연관 서비스에서 제공하는 이벤트, 상품안내 등과 같은 다양한 서비스 및 최신 정보를 안내 받을 수 있습니다. <br>
            </div>
            <div>
                4)장기 미이용회원(휴면회원)은 서비스를 최종 이용 후 1년간 로그인 기록이 없는 회원을 말합니다. 이 회원의 개인정보는 별도로 분리되어 안전하게 보관하게 되며, 대상자에게 안내는 분리 보관 처리일을 기준으로 최소 30일 이전에 이메일로 전송됩니다. <br>
            </div>
            <div>
                5)장기 미이용회원이 서비스를 지속적으로 이용하시고자 하는 경우 , 모바일앱에서 계정 활성화 조치를 직접하시고 로그인할 수 있습니다. <br>
            </div>
            <div>
                6) 회사는 만 14세 이상인 경우에 회원가입을 가능하도록 하며 개인정보의 수집·이용에 법정대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다. 단, 법정대리인의 동의를 얻은 경우에는 만 14세 미만 회원의 개인정보를 수집·이용할 수 있으며, 이 경우 법정대리인의 이름, 생년월일, 휴대폰 번호, 이메일 주소, 본인확인 값(CI, DI)을 추가로 수집할 수 있습니다. 다만 일의 밀도 서비스(공사체크앱)는 만 18세 이상으로 한정하고 있습니다. <br>
            </div>
            <div>
                7) 회사가 제공하는 서비스 이용과정에서 회원 본인이 자발적으로 공개한 개인정보 및 게시물과 관련한 권리와 책임은 작성자 본인에게 있습니다.<br>
            </div>
            <div>
                8) 자발적으로 공개된 정보는 보호받기 어려우며 타인에 의해 무단으로 수집되거나 처리될 수 있으며, 이로 인해 발생한 손실이나 문제는 전적으로 본인에게 있음을 유념하시기 바랍니다. 서비스 이용과정에서 개인에 관한 정보는 공개되지 않도록 심혈을 기울여 주시기 바랍니다.
            </div><br>

            

        </article>
  
        <article>
        <div class="Main">제 3조 (개인정보 수집 동의 거부)</div><br>
        <div>회원은 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동의를 거부할 경우 받는 별도의 불이익은 없습니다.</div>
        <div>단, 필수 동의 사항에 동의를 거부할 경우, 서비스 이용이 불가능하거나 서비스 이용 목적에 따른 서비스 제공에 제한이 따르게 됩니다.</div>
        <div>또한, 선택 동의 사항에 동의를 거부할 경우에도 선택 동의 개인정보 항목을 이용하여야 제공받을 수 있는 서비스의 전부 또는 일부 이용이 제한될 수 있습니다.</div><br>
        </article>
  
        <article>
        <div class="Main">제 4조 (개인정보의 제 3자 제공)</div><br>
            <div>회사는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</div>
            <div>그 밖에 개인정보의 제3자 제공이 필요한 경우에는 합당한 절차를 통한 동의를 얻어 제공합니다.</div>
            <div>회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</div><br>
            <div>개인정보 제 3자 제공 현황</div><br>
  
            <table>
                <tr>
                    <th>제공목적</th>
                    <th>제공받는자</th>
                    <th>제공항목</th>
                    <th>제공받는 자의 보유 및 이용기간</th>
                </tr>
                <tr>
                    <td>
                        일의 밀도 이벤트, 행사안내, 맞춤형 안내 제공, 서비스 이용통계 설문 
                    </td>
                    <td>
                        데일리밀도
                    </td>
                    <td>
                        이메일, 연락처(휴대전화번호), 서비스 이용 기록, 프로젝트 진행 일정 및 갯수
                    </td>
                    <td>
                        이용동의 철회 시, 회원탈퇴시 삭제
                    </td>
                </tr>
                <tr>
                    <td>
                        프로젝트의 신뢰를 바탕으로한 원활한 진행을 위해 상호 인식 정보 공유
                    </td>
                    <td>
                        프로젝트 참여자 간 
                    </td>
                    <td>
                        이름, 연락처, 프로젝트 기본정보(현장명, 주소, 담당관리자 연락처), 담당권한내의 정보
                    </td>
                    <td>
                        프로젝트 마스터의 삭제 요청이 있을시(단, 관련자 요청이 있을경우는 요청이 해소될때까지 보관) 
                    </td>
                </tr>
            </table>
            <br/>
  
        </article>
        <article>
            <div class="Main">제 5조 (개인정보의 처리 위탁)</div><br>
            <div>회사는 현재 개인정보 업무처리를 위탁운영 하지 않고 있습니다.</div>
            <div>회사의 개인정보 파기절차 및 방법은 다음과 같습니다.</div><br>
  
            <div class="Main">제 6조 (개인정보 파기절차 및 방법) </div><br>
            <div>1) 파기 절차 </div>
            <div>회원이 서비스 이용 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후 파기합니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않으며, 개인정보가 제3자에게 위탁된 경우에는 수탁자에게도 파기하도록 지시합니다 </div>
            <div>2) 파기 기한</div>
            <div>회원의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.</div>
            <div>3) 파기 방법</div>
            <div>- 종이에 출력된 개인정보: 분쇄기로 분쇄하거나 소각</div>
            <div>- 전자적 파일 형태로 저장된 개인정보: 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제</div><br>
            <div class="Sub">2. 예외적 보관 </div><br>
            <div>1) 관련 법령 및 회사 운영 정책에 의한 정보 보유 사유</div>
            <div>회원이 개인정보 제공에 대한 동의를 철회하는 등 개인정보의 수집 및 이용목적이 달성된 경우에도, 관계법령의 규정과 회사의 운영 정책에 의하여 보존할 필요가 있는 경우 일정한 기간 동안 회원 개인정보를 보관합니다.</div>
            <div>이 경우 회사는 해당 정보를 아래의 보존 이유에 따른 목적과 기간 내에서만 보관할 수 있습니다.</div>
            
            <table>
                <tr>
                    <th>항목</th>
                    <th>보존이유</th>
                    <th>보존기간</th>
                </tr>
                <tr>
                    <td>
                        소비자의 불만 또는 분쟁처리에 관한 기록
                    </td>
                    <td>
                        전자상거래등에서의 소비자보호에 관한 법률 제6조 및 시행령 제 6조
                    </td>
                    <td>
                        5년
                    </td>
                </tr>
                <tr>
                    <td>
                        접속에 관한 기록
                    </td>
                    <td>
                        통신비밀보호법 제 15조의 2 및 시행령 제 41조
                    </td>
                    <td>
                        3개월
                    </td>
                </tr>                
                <tr>
                    <td>
                        부정이용 기록
                    </td>
                    <td>
                        부정거래등 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 제재
                    </td>
                    <td>
                        5년
                    </td>
                </tr>                
                <tr>
                    <td>
                        계약 또는 청약철회 등에 관한 기록
                    </td>
                    <td>
                        전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 시행령 제6조
                    </td>
                    <td>
                        5년
                    </td>
                </tr>                
                <tr>
                    <td>
                        대금결제 및 재화 등의 공급에 관한 기록
                    </td>
                    <td>
                        전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 시행령 제6조
                    </td>
                    <td>
                        5년
                    </td>
                </tr>
            </table><br>
            <div>※ 부정이용: 부정이용은 아래의 경우를 의미합니다.</div>
            <div>- 법령 또는 회사와 회원 간의 서비스 이용약관에 위배되는 방법이나 내용의 거래</div>
            <div>- 기타 회사, 회원 또는 타인의 권리나 이익을 침해하는 방법이나 내용의 거래</div>
            <div>- 공서양속을 해치는 방법이나 내용의 거래</div>
            <div>※ 부정이용에 대한 보존 항목: ID, 휴대폰 번호, 이메일 주소, 생년월일, 부정거래 사유, 탈퇴 시점의 회원 상태 값 등</div>
            <br>
            <div class="Sub">3. 휴면계정의 정보 처리 </div><br>
            <div>1) 장기 미이용회원(휴면 회원)은 서비스를 최종 이용 후 1년 간 서비스 이용 기록이 없는 회원을 말합니다.</div>
            <div>2) 1년 이상 서비스 이용 기록이 없는 해당 회원의 개인정보는 개인정보보호법 제39조의6, 동법 시행령 제48조의5 에 따라 일반 이용자의 개인정보와 분리하여 별도로 저장 및 관리합니다.</div>
            <div>3) 다만 회원가입 시 ‘장기 미접속 시 계정 활성 상태 유지‘를 선택한 회원의 계정은 장기 미 접속 상태이더라도 휴면 처리되지 않으며, 회원이 직접 탈퇴 신청할 때까지 개인정보를 보관합니다.</div><br>
        </article>
  
        <article>
            <div class="Main">제 7조 (회원및 법정대리인의 권리 의무 행사방법)</div><br>
            <div class="Sub">1. 회원의 권리 </div><br>
            <div>회원은 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.</div><br>
            <div>1) 정보주체는 언제든지 자신의 개인정보를 조회하고 수정할 수 있습니다.</div>
            <div>2) 정보주체는 언제든지 개인정보 열람, 정정, 삭제, 처리정지 등을 요청할 수 있습니다.</div>
            <div>3) 정보주체는 회원 탈퇴 절차를 통하여 개인정보 제공에 대한 동의를 철회할 수 있습니다.</div>
            <div>4) 제2,3호에 따른 권리 행사는 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 요청하실 수 있으며 회사는 요청자가 본인인지를 확인하고 지체 없이 조치하겠습니다. </div>
            <div>5) 회원이 개인정보에 대한 열람·정정·삭제·처리정지를 요청한 경우, 회사는 본 조 제6항의 경우를 제외하고 요청을 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다. 다만, 이전의 개인정보가 이미 제공된 경우에는 처리 결과를 제3자에게 통지하여 정정할 수 있습니다.</div>
            <div>6) 회사는 제1,2항의 요청이 개인정보보호법 제35조 4항, 제27조 2항에 해당하는 경우 해당 요청을 거절할 수 있습니다.</div>
            <div>- 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</div>
            <div>- 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</div>
            <div>- 공공기관이 개인정보를 처리하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우</div>
            <div>- 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우 등</div>
            <div>7) 제1,2,3항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</div><br>
            <div class="Sub">2. 법정 대리인의 권리 </div><br>
            <div>1) 회사는 아동의 개인정보를 보호하기 위하여, 만 14세 미만의 아동의 경우 신규 회원 가입을 제한하고 있습니다. 단, 법정대리인의 동의를 얻은 경우 해당 아동의 개인정보 수집·이용이 가능하며, 법정대리인에 대하여 아래와 같은 권리를 보장합니다.</div>
            <div>2) 법정대리인은 14세 미만 아동의 개인정보에 대해 열람, 정정, 삭제, 처리정지 등을 요청할 수 있으며, 수집·이용 또는 제공에 대한 동의를 철회할 수 있습니다.</div>
            <div>3) 제2항에 따른 권리 행사를 위해 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 하며, 회사는 요청자가 진정한 법정대리인인지를 확인하고 지체 없이 조치하겠습니다.</div><br>
            <div class="Sub">3 개인정보의 철회 및 거부권 행사, 탈퇴 방법 </div><br>
            <div>3. 개인정보의 철회 및 거부권 행사, 탈퇴 방법</div><br>
            <div>개인정보의 열람 및 정정 범위, 동의 철회, 광고성 정보 전송에 대한 동의 거부권 행사, 회원 탈퇴 방법은 아래와 같습니다.</div><br>
            <div>1) 개인정보의 열람 및 정정 범위</div>
            <div>- 회사가 보유하고 있는 회원의 개인정보</div>
            <div>- 회사가 이용하거나 제3자에 제공한 개인정보</div>
            <div>- 개인정보의 수집, 이용, 제공 등의 동의 현황</div>
            <div>2) 개인정보의 열람·정정 및 동의 철회, 광고성 정보 전송에 대한 동의 거부권 행사 방법</div>
            <div>-홈페이지 (웹, 어플리케이션)</div>
            <div>-고객센터</div>
            <div>3) 회원 탈퇴</div>
            <div>개인정보 주체는 위의 수단 외에, 행정안전부의 '개인정보보호 종합지원 포털' 웹사이트(www.privacy.go.kr)를 통하여서도 개인정보 열람 청구를 하실 수 있습니다.</div>
            <div>4. 회원(또는 법정대리인)의 의무</div>
            <div>회원(또는 법정대리인)는 개인정보주체로서 개인정보를 보호받을 권리와 함께 스스로를 보호할 의무가 있습니다.</div>
            <div>1) 회원은 개인정보를 항상 최신의 상태로 유지해야 합니다.</div>
            <div>2) 회원은 ID, 비밀번호, 접근매체 등 개인정보가 유출되지 않도록 주의해야 하며 제3자에게 개인정보 및 계정 정보를 양도하거나 대여할 수 없습니다.</div>
            <div>3) 회원은 회사의 개인정보보호정책에 따라 보안을 위한 비밀번호의 주기적 변경에 협조해야 합니다.</div>
            <div>4) 회원은 "정보통신망 이용촉진 및 정보보호 등에 관한 법률", “개인정보 보호법”, "주민등록법" 등 기타 개인정보에 관한 법률을 준수하여야 합니다.</div>
            <div>5) 타인의 개인정보를 도용하여 회원가입하거나 주민등록번호 등을 도용하여 물품 거래를 시도·실행한 경우에는  회원자격 상실과 함께 주민등록법에 의거하여 처벌될 수 있습니다.</div>
            <div>6) 본 조의 의무를 다하지 않아 문제가 발생한 경우, 모든 책임은 회원에게 있으며 회사는 책임지지 않습니다.</div>
            <div>- 회원 본인이 ID, 비밀번호, 접근매체 등의 양도·대여·분실을 하는 경우</div>
            <div>- 로그인 상태에서의 이석 등으로 제3자가 회원의 정보를 이용할 수 있게 된 경우</div>
            <div>7) 또한 회원 본인의 부주의나 관계법령에 의한 보안조치로 차단할 수 없는 방법이나 기술을 사용한 해킹 등 이는 회사가 상당한 주의를 기울였음에도 불구하고 통제할 수 없는 상황에서 발생한 문제에 대해서는 회사는 책임지지 않습니다.</div>
        </article><br>
  
        <article>
            <div class="Main">제 8조 (개인정보의 기술적 관리적 보호 조치)</div><br>
            <div>회사는 개인정보보호법 제29조에 따라 회사의 개인정보 처리자를 최소한으로 제한하며, 기술적 조치와 함께 개인정보 처리자에 대한 교육 등 관리적 조치를 통해 개인정보보호의 중요성을 인식시키고 있습니다.</div><br>
            <div class="Sub">가. 기술적 조치 </div><br>
            <div>1) 회원의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능(Lock)을 사용하여 중요한 데이터는 별도의 보안 기능을 통해 보호합니다. (Linode 클라우드 서비스를 통해 고객 데이터를 보관함)</div>
            <div>2) 회원의 비밀번호는 일 방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인 및 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.</div>
            <div>3) 회사는 백신 프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신 프로그램은 주기적으로 업데이트되며 갑작스러운 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.</div>
            <div>4) 회사는 암호 알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL 등)를 채택하고 있습니다.</div>
            <div>5) 회사는 해킹 등 외부 침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석 시스템 등을 이용하여 보안에 만전을 기하고 있습니다.(Linode 클라우드 서비스를 통해 고객 데이터를 보관함)</div>
            <div>참고 - https://www.linode.com/ko/products/cloud-firewall/</div><br>
            <div class="Sub">나. 관리적 조치 </div><br>
            <div>1) 회사는 회원의 개인정보에 대한 접근 권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다.</div>
            <div>- 회원을 직접 상대로 하여 마케팅 업무를 수행하는 자</div>
            <div>- 개인정보보호책임자 및 담당자 등 개인정보 보호업무를 수행하는 자</div>
            <div>- 기타 업무 상 개인정보의 취급이 불가피한 자</div>
            <diiv>2) 개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을 실시하고 있습니다.</diiv>
            <div>3) 입사 시 보안서약서를 통하여 사람에 의한 정보 유출을 사전에 방지하고 개인정보보호 관련 규정에 대한 이행사항 및 직원의 준수 여부를 감사하기 위한 내부 절차를 마련하고 있습니다.</div>
            <div>4) 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확히 하고 있습니다.</div>
            <div>5) 회사는 회원 개인의 실수나 회사의 귀책사유 없이 발생하는 개인정보관련 문제에 대해서는 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 아이디와 비밀번호를 적절하게 관리하며 이에 대한 책임을 져야 합니다.</div>
            <div>6) 그 외 내부 관리자의 실수나 기술 관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 회사는 즉각 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.</div><br>
        </article>
  
        <article>
            <div class="Main">제 9조 (개인정보 보호책임자)</div><br>
            <div>회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만 처리 및 피해 구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 개인정보를 보호하는 데 있어 회원에게 고지한 사항들에 반하여 사고가 발생할 시에 개인정보보호책임자가 모든 책임을 집니다.</div><br>
            <div>그러나, 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크 상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다.</div><br>
            <div class="Sub">1. 개인정보 보호 책임자 </div><br>
            <div>개인정보 보호책임자는 다음과 같으며, 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다</div>
            <div>성명 :편용우</div>
            <div>직위: 대표</div>
            <div>연락처 : 메일 pyunyw@dailymildo.com</div><br>
        </article>
  
        <article>
            <div class="Main">제 10조 (권익 침해에 대한 구제 방법)</div><br>
            <div class="Sub">1. 기관정보 </div><br>
            <div>아래의 기관은 회사와 별개의 기관으로, 회사의 자체적 개인정보 처리, 피해구제 결과에 만족하지 못하시거나,개인정보가 침해 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</div><br>
            <div>개인정보 침해신고센터 (한국인터넷진흥원 운영) : (국번없이) 118 / privacy.kisa.or.kr</div>
            <div>대검찰청 사이버범죄수사단 : (국번없이) 1301 / www.spo.go.kr</div>
            <div>경찰청 사이버안전국 : (국번없이) 182 / www.cyber.go.kr</div>
            <div>전자거래분쟁조정위원회 (https://www.ecmc.or.kr / 1661-5714)</div><br>
            <div class="Sub">2. 도용된 개인정보에 대한 조치 </div><br>
            <div>회원이 타인의 개인정보를 도용하여 회원가입 등을 하였음을 알게 된 때에는 지체 없이 해당 아이디에 대한 서비스 이용정지 또는 회원 탈퇴 등 필요한 조치를 취합니다. 이때 개인정보가 도용됨을 주장하는 회원의 본인 확인 방법으로는 전자정부에서 시행하는 주민등록증 진위 확인 서비스를 이용합니다.</div><br>
        </article>
  
        <article>
            <div class="Main">제 11조 (고지의 의무)</div><br>
            <div>가. 본 방침은 정부나 회사의 정책이 변경됨에 따라 내용의 추가 및 삭제, 수정이 있을 수 있으며, 이 경우 시행 7일 전에 홈페이지 또는 이메일 등을 통해 사전 공지하고 사전 공지가 곤란한 경우 지체 없이 공지합니다.</div>
            <div>나. 회원은 개인정보의 수집, 이용, 제공, 위탁 등과 관련한 위의 사항에 대하여 원하지 않는 경우 동의를 거부할 수 있습니다. 다만, 회원이 동의를 거부하는 경우 서비스의 전부 또는 일부를 이용할 수 없음을 알려드립니다.</div>
            <div>다. 회사가 변경 내용의 고지를 통해 안내한 변경 적용일까지 회원이 명시적으로 거부 의사를 표시하지 않는 경우, 변경 내용에 동의한 것으로 간주합니다.</div>
            <div>라. 또한 당사는 개인정보의 수집 및 활용, 제3자 제공 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령에 따라 회원의 별도 동의가 필요한 사항과 관련된 내용이 추가, 변경되는 경우에는 관련 법령에 따른 회원의 별도 동의를 받습니다.</div>
        </article>

        <article>
            <div>- Subject to the User's consent to allow access the phone contacts in the order to synchronize and contact</div>
            <div> - We store your up-to-date contacts in order to add friends and invite them to the project/process as soon as one of your contacts signs up for GongCheck and to properly display names in the contact list. We only need the number and name (first and last) for this to work and store no other data about your contacts.</div>
            <div>- Can always stop syncing contacts or delete them from our servers in Settings > Privacy & Security > Data Settings.</div>
        </article>
    </section>
    </body>
  </template>
  
  
  <script>
  import HeaDer from '../Header/HeaDer.vue'
  
  export default{
  name : 'PolicyPage',
  components: {
    HeaDer,
  }
  }
  </script>
  
  <style>
  .Policyinner{
    background: gray;
    color: white;
    padding: 50px;
    text-align: left;
    padding-left: 5%;
    padding-bottom: 15%;
  }
  td, th ,tr{
  padding: 15px;
  border: 1px solid #ccc;
  text-align: center;
  }
  .pageHader{
    font-size: 30px;
    font-weight: bolder;
  }
  .Main{
    font-size: 25px;
    font-weight: bolder;
  }
  .Sub{
    font-size: 20px;
    font-weight: bolder;
  }
  </style>
  