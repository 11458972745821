<template>
  <div>

  </div>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* 중앙정렬 */
  text-align: center;
  /* 여백제거 */
  padding: 0;
  margin: 0;
  /* 드래그방지 */
  -ms-user-select: none; -moz-user-select: none; -khtml-user-select: none; -webkit-user-select: none; user-select: none;
}
</style>
