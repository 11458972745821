<template>
  <div>
    <ServicePage/>
  </div>
</template>

<script>
import ServicePage from '@/components/page/SubPage/ServicePage.vue'
export default {
  name: 'ServiceView',
  components: {
    ServicePage,
  }
}
</script>