<template>
  <div>
    <PolicyPage/>
  </div>
</template>

<script>
import PolicyPage from '@/components/page/SubPage/PolicyPage.vue'


export default {
  name: 'PolicyView',
  components: {
    PolicyPage,
  }
}
</script>