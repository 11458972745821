<template>
  <div>
    <body style="background: grey;">
    <div>
        <HeaDer/>
    </div>
    <section class="Serviceinner">
        <article class="pageHader">
            <div>서비스 이용 약관</div>
        </article>
        <br>
        <article>
            <div class="Main"> &lt; 1장 총칙 &gt;</div><br>
            <div class="Sub">제 1조 (목적)</div><br>
            <div>이 약관은 주식회사 데일리 밀도(이하”회사”라고 합니다.)가 스마트폰등의 이동통신기기를 통해 제공되는 모바일 애플리케이션 (이하 “공사체크” 이라고 합니다.)을 통하여 제공하는 서비스 및 기타 정보서비스 (이하”서비스”라고합니다.)와 관련하여 회사와 회원 또는 회사와 비회원간의 권리와 의무 책임 사항 및 회원의 서비스 이용 절차에 관한 사항을 규정함을 목적으로 합니다.</div><br><br><br>
            <div class="Sub">제 2조 (약관의 명시, 효력 및 변경)</div><br>
            <div>본 약관은 일의밀도 회원으로 가입하는 시점부터 효력이 발생합니다.  비 회원의 경우, 이용 가능한 서비스와 관련된  약관을 적용받습니다. </div><br>
            <div>회사는 본 약관의 내용과 상호, 회사 소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 서비스를 이용하고자 하는 자와 회원이 알 수 있도록 서비스가 제공되는 일의 밀도 사이트 초기화면(이하 연결 화면 포함)과 일의밀도(공사체크) 어플리케이션에 게시합니다.</div><br>
            <div>회사는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률등  관계법령에 위배되지 않는 범위 내에서 본 약관을 개정할 수 있습니다. </div><br>
            <div>회사가 이 약관을 개정하는 경우에는 개정된 약관의 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 그 적용일자 7일 (단, 회원에게 불리한 내용으로 변경하는 경우에는 30일) 이전부터 적용일자 전일까지 본조 2항의 방법 또는 회원이 등록한 Email로 공지합니다. 개정된 약관은 개정 약관에서 소급효를 부여하지 않는 이상 적용일자 이전으로 소급하여 적용되지 아니합니다.</div><br>
            <div>이 약관은 회사와 회원 간에 성립되는 서비스 이용계약의 기본약정입니다. 회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하”개별약관”이라고 합니다.)을 정하여 미리 공지할수 있습니다. 회원이 이러한 개별약관에 동의하고 특정서비스를 이용하는 경우에는 개별약관이 우선적으로 적용되고. 이 약관은 보충적인 효력을 갖습니다. 개별약관의 게시, 변경에 관해서는 위의 1항, 2항, 3항을 준용합니다. 단 회사는 개별약관을 일의밀도 사이트의 화면에 게시 할수 있습니다. </div><br>
            <div>회사가 본조 4항에 따라 개정약관을 공지 또는 통지 하였음에도 불구하고 회원이 명시적으로 거부의사를 표시하지 아니하는 경우 회원이 개정약관에 동의한 것으로 봅니다. </div><br>
            <div>본 약관에 동의 하는 것은 서비스를 정기적으로 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원의 피해에 대하여 회사는 책임을 지지 않습니다. </div><br>
            <div>회원은 변경된 약관에 동의하지 아니하는 경우 서비스의 이용을 중단하고 이용계약을 해지할 수 있습니다.</div><br><br><br><br>
            <div class="Sub">제 3조 (약관의 준칙)</div><br>
            <div>이 약관 에서 정하지 않은 사항은 전자문서 및 전자거래 기본법, 정보통신망 이용촉진 및 정보보호등에 관한 법률, 전기 통신사업법 등 관계법령, 전자상거래등에서 소비자보호에 관한 법률, 저작권법, 개인정보 보호법 등 관련 법령의 규정과 일반적인 상관례에 의합니다. </div><br>
            <div>회원은 회사가 제공하는 서비스를 이용함에 있어서 관련 법령을 준수하여야 하며, 이 약관의 규정을 들어 관련 법령 위반에 대한 면책을 주장 할 수 없습니다.</div><br><br><br><br>
            <div class="Sub">제 4조 (서비스의 종류 및 목적)</div><br>
            <div>1.회사가 회원에게 제공하는 서비스의 목적은  다음 각목과 같습니다. </div><br>
            <div>정보의교환서비스 : 회사는 공사체크 앱의 프로젝트 참여자들에게 공유의 가치를 만들고, 적정한 정보의 교환을 신속하고 정확하게 하며 이를 기록으로 남김으로써 거래의 신뢰를 더욱 공고히 하기 위해 다양한 서비스를 만들었으며, 더 다양한 서비스를 개발 진행하고 있습니다.</div><br>
            <div>이력 관리서비스: 회사가 일의밀도 앱서비스를 통해 진행한 프로젝트 및 공정관리 사항 등의 변동기록을 지속적으로 기록 보관할 수 있게 함으로써 경력을 저장하고 활용할 수 있도록 할 수 있습니다.</div><br>
            <div>공정관리서비스 : 프로젝트를 사전 기획하고 사후 진행되는 사항들을 진척사항, 인원사항 등을 지속적으로 기록, 관찰을 기록할 수 있으며, 상호 거래자들 과의 신뢰를 구축할 수 있다.</div><br><br><br><br>
            <div class="Sub">제 5조 (용어의 정의)</div><br>
            <div>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</div><br>
            <div>A. 서비스 : 공사체크 앱 이용고객 및 회원이 PC, 휴대형 단말기, 태블릿PC등 각종 유무선 기기 또는 프로그램을 통하여 이용할 수 있도록 회사가 제공하는 일체의 용역( 프로젝트 공정, 인원, 일정관리 및 제공 콘텐츠, SNS 및 그와 관련된 서비스)을 말합니다. </div><br>
            <div>B. 회원 : 회사의 서비스에 접속하여 본 약관에 동의하고 ID 와 PASSWORD를 발급 받았으며 회사가 제공하는 서비스를 이용하는 고객을 포괄적으로 의미합니다. 회원은 권한의 설정에 따라 다음과 같이 구분됩니다.기본적으로 프로젝트의 개시,한정된 전문용역의 제공등에 따라 나뉩니다.</div><br>
            <div style="padding-left: 50px;">a. 디렉터: 프로젝트 페이지를 만들고 모든 프로젝트를 관리하는 권한을 가진 자 </div>
            <div style="padding-left: 50px;">b. 매니져: 서비스를 사용하는 모든 개인으로 프로젝트를 개시하고 관리 하는 자 </div>
            <div style="padding-left: 50px;">c. 전문가: 관리자 혹은 대표관리자로부터 초대되어 일부 정보 열람이 가능한 권한을 가진 회원이며 프로젝트내에서 전문영역의 업무와 서비스를 제공하는자</div><br>
            <div>C. 해지 :  회사 또는 회원이 이용계약을 해약하는 것을 말합니다.</div>
            <div>D: 프로젝트 : 현재 진행 예정 혹은 진행할 예정의 인테리어 공사 및 디자인 등 계획을 말합니다.</div>
            <div>E: 포트폴리오: 각 프로젝트 단계에서 작업이 최종 완성된 이후 게시된 결과물들 말합니다.</div>
            <div>F: 마이페이지: 가입된 회원의 정보를 보여주며, 참여한 프로젝트의 리스트를 보여줍니다.</div> <br><br><br>
        </article>
        <article>
            <div class="Main"> &lt; 2장 서비스 이용 계약 &gt;</div><br><br>
            <div class="Sub">제 6조 (서비스의 이용 계약)</div><br>
            <div>1. 회사가 제공하는 서비스에 관한 이용계약(이하”이용계약”이라고 합니다.)은 해당 서비스를 이용하고자 하는 자의 이용신청(회원가입신청)에 대하여 회사가 승인함으로써 성립합니다. 회사는 이용승낙의 의사를 해당 서비스 화면에 게시하거나, 메일 또는 기타 방법으로 이용신청자에게 통지합니다.</div><br>
            <div>2.가입 신청자가 회원정보를 제출하는 것은 회원가입 화면에  노출되어 있는 본 약관 및 개인정보처리 방침 내용을 숙지하고, 회사가 서비스 이용을 위해 운영하는 각종정책(저작권 정책, 운영방침 등 ) 과 수시로 공지하는 사항을 준수하는 것에 대해 동의하는 것으로 봅니다.</div><br>
            <div>3.회원가입은 만 14세 이상의 개인 또는 기업 (개인사업자 및 법인사업자 등)이 할 수 있습니다. 다만 만 18세 이하 이하의 개인은 서비스의 정책에 따라 사용의 제한이 있을수 있습니다. 이용신청자는 실명으로 가입신청을 하여야 하며, 실명이 아니거나 타인의 정보를 도용하여 회원으로 가입한자는 회사가 제공하는 서비스를 이용할 수 없으며, 이용하여서도 안 됩니다. </div><br>
            <div>4.회원의 계정은 실명 1인당 1계정을 원칙으로 합니다.</div><br>
            <div>5.회사는 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다. (SNS 연동을 통한 확인 포함)</div><br><br><br>
            <div class="Sub">제 7조 (이용 신청)</div><br>
            <div>
                1.서비스 이용신청은 회사가 요청한 양식에 따른 회원정보(전자우편주소, 비밀번호, 별명 등 )를 기입하고 가입신청을 하여야 합니다. <br><br>
                2.가입신청 양식에 기재하는 모든 회원 정보는 실제 데이터인 것으로 간주합니다. 실명이나 실제 정보를 입력하지 않는 경우에는 서비스 이용에 제한을 받거나 법적 보호를 받지 못할 수 있습니다.
            </div><br><br>
            <div class="Sub">제 8조 (이용신청의 승인 및 제한)</div><br>
            <div>1.회사는 제 7조에 따른 이용자의 이용신청에 대하여 신용정보의 이용 및 보호에 관한 법률이 정하는 신용불량자로 등록되어 있는 등의 특별한 사정이 없는 한, 접수 순서대로 이용 신청을 승낙합니다.</div><br>
            <div>2.회사는 각 호에 해당하는 경우 이용신청을 승인하지 않을 수 있습니다.</div><br>
            <div>
                A, 만 18세 미만의 자가 이용신청을 하는 경우 <br>
                B. 본인의 실명으로 신청하지 않은 경우  <br>
                C. 다른 사람의 명의를 도용하여 신청한 경우 <br>
                D. 이용신청 시 허위 정보를 기재하거나, 허위서류를 첨부하는 경우, 회사가 제시하는 내용을 기재하지 않는 경우 <br>
                E. 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청한 경우 <br>
                F. 본 약관 제 9조에 따라 회원 자격을 상실한 경우 <br>
                G. 이용자의 귀책사유로 인해 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우 <br>
                H. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 <br>
                I.   기술상 서비스 제공이 불가능한 경우 <br>
                J. 개인에 의해 등록된 회사의 요청이나, 허위기재를 확인된 경우  <br>
                K. 프로젝트의 대표관리자, 관리자(디렉터,매니져)는 각 프로젝트에 참여한 하위 등급회원의 등급에 따라 서비스메뉴 및 정보공개정도에 차등을 둘 수 있습니다. <br><br>
            </div>
            <div>3. 회사는 다음과 같은 사유가 있는 경우, 이용신청에 대한 승인을 유보할 수 있습니다. 이 경우, 회사는 이용신청자에게 승인유보의 사유, 승인가능시기 또는 승인에 필요한 추가정보 내지 자료 등 기타 승인의 유보와 관련된 사항을 서비스 화면을 통하여 통지합니다.</div><br>
            <div>
                A. 설비에 여유가 없는 경우 <br>
                B. 기술상 지장이 있는 경우 <br>
                C. 기타 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우 <br>
            </div><br><br>
            <div class="Sub">제 9조 (이용계약의 종료)</div><br>
            <div>
                1. 회원 또는 회사는 이 약관에서 정한 절차에 따라 이용계약을 해지 할 수 있습니다. <br><br>
                2. 회원의 해지
            </div><br>
            <div>
                A. 회원은 언제든지 회사에게 해지의사를 통지함 으로써 이용계약을 해지할 수  있습니다.. 회사는 온라인으로 이용계약을 해지할 수 있는 절차를 제공합니다. 다만, 회원은 해지의사를 통지하기 전 진행중인 프로젝트 관계자들에게 해지를 통보하여야 합니다. 이 경우 생기는 불이익은 회원 본인이 부담하여야 합니다. <br>
                B. 이용계약은 회원의 해지의사가 회사에 도달한 때에 종료됩니다. 다만 회원이 제A호의 조치를 마치지 않은 경우 A호의 조치가 완료된 때에 종료됩니다. <br>
                C. 본 항에 따라 해지를 한 회원은 이 약관이 정하는 회원가입절차와 관련조항에 따라 회원으로 다시 가입할 수 있습니다. 다만 회원이 중복참여가 제한된 부정한 목적으로 회원탈퇴 후 재가입 신청하는 경우 회사는 재가입을 일정기간 동안 제한할 수 있습니다. <br>
            </div><br>
            <div>
                3. 회사의 해지<br>
            </div><br>
            <div>
                A. 회사는 다음과 같은 사유가 있는 경우, 이용계약을 해지할 수 있습니다. 이 경우 회사는 회원에게 전화, 기타의 방법(회원가입시 회원이 회사에 통지한 연락방법)을 통하여 해지사유를 밝혀 해지의사를 통지합니다. 이 경우 회사는 해당 회원에게 사전에 해지사유에 대한 의견진술의 기회를 부여할 수 있습니다. <br>
            </div><br>
            <div>
                a. 회원에게 제8조 2항에서 정하고 있는 이용신청의 승낙거부사유가 있음이 확인된 경우 <br>
                b. 회원이 공공질서 및 미풍양속에 위배되는 행위를 하거나 시도한 경우 <br>
                c. 회원이 회사나 다른 회원 기타 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 행위를 한 경우 <br>
                d. 회원이 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우 <br>
                e. 회원에게 파산, 피후견(구법상의 금치산, 한정치산에 준하는 경우), 회생, 파산의 결정 또는 선고, 사망, 실종선고, 해산, 부도, 무자력 등 정상적 서비스이용을 불가능하게 하거나 곤란하게 하는 사유가 발생한 경우 <br>
                (다만, 이 경우 회사는 이용계약 해지 대신 거래안전 보호를 위해 회원자격정지, 서비스 이용제한 등의 조치를  취할 수 있습니다.)<br>
            </div><br>
            <div> B. 이용계약은 회사의 해지의사가 회원에게 도달한 때에 종료되나, 회원이 제공한 연락처 정보의 오류로 인하여 회사가 해지의사를 통지할 수 없는 경우에는 회사가 메세지에 해지의사를 공지하는 시점으로부터 3일 이후 종료됩니다. 이 경우 공지 시점부터 회원의 권리는 모두 중지, 소멸되고 3일 이전에 권리를 회복할 사유가 발행하면 소급하여 권리가 회복됩니다.</div><br>
            <div></div><br><br>
        </article>
        <article>
            <div class="Main"> &lt; 3장 회원정보의 보호 &gt;</div><br><br>
            <div class="Sub">제 10조 (회원정보의 수집과 보호)</div><br>
            <div>
                1. 회사는 이용계약을 위하여 회원이 제공한 정보 외에도 수집목적 또는 이용목적 등을 밝혀 회원으로부터 필요한 정보를 수집, 이용할 수 있습니다. 이 경우, 회사는 회원으로부터 정보수집. 이용에 대한 동의를 받습니다. 회사는 개인정보 수집. 이용. 제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집.이용.제공에 관한 회원의 동의 거절시 제한되는 서비스를 구체적으로 명시하고, 필수 수집항목이 아닌 개인정보의 수집. 이용. 제공에 관한 이용신청자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
            </div><br>
            <div>
                2. 회사가 정보수집. 이용을 위하여 회원의 동의를 받는 경우, 회사는 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항 (제공받는 자, 제공목적, 제공정보의 내용)을 미리 명시하거나 고지합니다. 회원은 정보제공에 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.
            </div><br>
            <div>
                3.회원은 회사에게 정보를 사실대로 제공하여야 합니다. 회사는 회원이 이 약관 제6조 5항에 따라 제공한 정보의 정확성을 확인하기 위하여 관련법령이 허용하는 범위 내에서 증빙자료의 제공을 요청할 수 있고, 회원이 정당한 사유없이 증빙자료를 제공하지 않는 경우 회사는 서비스의 이용을 제한하거나 이용계약을 해지할 수 있습니다.
            </div><br>
            <div>
                4.회사는 생년월일, 성별, 실명, 휴대폰번호, 경력사항, 자기소개서 등 서비스화면을 통하여 공개되는 정보를 제외하고는 회사가 수집한 회원정보를 정보수집 시에 밝힌 수집목적, 이용목적, 원활한 서비스의 제공 및 제공서비스의 확대 외의 용도로 사용하거나 제3자에게 제공하지 않습니다. 회사가 제3자에게 회원의 개인정보를 제공할 필요가 있는 경우 회사는 제공될 개인정보 항목, 제공받는자, 제공받는 자의 개인정보 이용목적 및 보유.이용기간 등을 명시하여 회원의 동의를 받고, 개인의 정보수집. 취급.관리 등을 위탁하는 경우에는 위탁업무의 내용 및 수탁사의 명칭 등에. 관한 사항을 서면, 전자우편, 문자전송, 전화 또는 홈페이지를 통해 미리 회원에게 고지하여 동의를 받거나 개인정보취급방침에 포함하여 일의밀도 어플리케이션에 게시합니다. 다만, 관련 법령에 달리 정함이 있는 경우와 다음의 경우에는 예외로 합니다. <br>
                (관리자가 프로젝트 참여 요청에 따라 회원이 참여 요청을 수락한 경우, 회원 간의 쌍방 연락처가 쌍방에 공개되며, 참여자, 관리자 회원의 실명, 생년월일, 초청자의 기업 상태 혹은 실명 및 연락처 정보가 공개됩니다.)
            </div><br>
            <div>
                5. 회사의 개인정보 관리책임자 및 연락처는 일의밀도 어플리케이션(공사체크) 내 개인정보취급방침에서 공지 합니다.
            </div><br>
            <div>
                6. 회원은 언제든지 자신에 관한 정보를 열람할 수 있고, 회사 또는 정보관리책임자에게 잘못된 정보에 대한 정정을 요청할 수 있습니다. 회사는 회원으로부터 정보정정의 요청이 있는 경우 그 오류를 정정하거나 정정하지 못하는 사유를 회원에게 통지하는 등 필요한 조치를 취할 때까지 당해 정보를 이용하지 않습니다. 다만, 법률에 따라 개인정보의 제공을 요청받는 경우에는 그러하지 아니합니다.
            </div><br>
            <div>
                7. 회사는 회원정보의 보호를 위하여 관리자를 최소한으로 한정하며, 회사의 고의 또는 과실로 인하여 회원정보가 분실, 도난, 유출, 변조되거나 위법하게 제3자에게 제공된 경우에는 그로 인한 회원의 손해에 대하여 모든 책임을 부담합니다.
            </div><br>
            <div>
                8. 이용계약이 종료된 경우, 회사는 당해 회원의 정보를 파기하는 것을 원칙으로 합니다. 다만, 아래의 경우에는 회원정보를 보관합니다. 이 경우 회사는 보관하고 있는 회원정보를 그 보관 목적으로만 이용합니다. <br><br>
                상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. <br>
                비방이나 허위사실 유포 등으로 타인에게 피해를 입힌 경우, 지적재산권 침해상품 판매, 기타 인터넷 사기행위 등으로부터 회원과 회사를 보호하고 법적 절차에 따른 수사 협조를 위한 목적으로 회사는 이용계약 종료 여부에 상관없이 회원의 거래정보 및 개인정보를 포함한 기타 이용관련기록 등의 필요한 정보를 보관 및 관련기관에 제공할 수 있습니다. <br>
                기타 정보수집에 관한 동의를 받을 때 보유기간을 명시한 경우에는 그 보유기간까지 회원정보를 보관합니다. <br>
                회원간 프로젝트를 공유하고 함께 진행되었을 경우 전체 참여 회원이 프로젝트 자료를 삭제하지 않은 경우에는 해당 회원의 기록이 삭제되지 않고 보관됩니다. <br>
            </div><br>
            <div>
                9. 회사는 회원정보의 보호와 관리에 관한 개인정보취급방침을 회원과 회사의 서비스를 이용하고자 하는 자가 알 수 있도록 일의밀도 어플리케이션(공사체크) 화면에 게시합니다.
            </div><br> 
            <div>
                10. 회사는 회원의 회원정보를 보호하기 위해 보안시스템을 구축 운영하며, “개인정보처리방침”을 공지하고 준수합니다. 또한, 회사는 “개인정보처리방침”에 따라 회원정보를 처리함에 있어 안정성 확보에 필요한 기술적 및 관리적 대책을 수립. 운영합니다.
            </div><br><br>
            <div class="Sub">제 11조 (회원정보의 변경)</div><br>
            <div> 
                회사는 회원정보의 보호와 권리에 관한 개인정보취급방침을 회원과 회사의 서비스를 이용하고자 하는 자가 알 수 있도록 일의밀도 어플리케이션(공사체크)에 게시합니다. <br>
                회사의 회원에 대한 통지는 어플리케이션의 화면상으로 통지되며, 회원정보가 변경되었음에도 해당 사항을 수정하지 않음으로써 발생한 각종 손해와 잘못된 수정으로 인하여 발생한 손해는 당해 회원이 부담하여야 하며, 회사는 이에 대하여 아무런 책임을 지지 않습니다. 또한, 회원이 변경된 정보를 수정하지 않거나 또는 허위 내용으로 수정하여 회사에게 손해를 입힌 경우에 회원은 이에 대한 손해배상 책임을 부담합니다.  <br>
                회원은 이용.신청시 기재한 사항이 변경되었을 경우 개인정보 설정 화면에서 회원정보를 온라인 수정 및 전자우편으로 회사에 그 변경사항을 알려야 합니다. 회원정보의 변경의 비통보로 인하여 발생한 손해의 책임은 회원에게 있습니다. <br>
                기업정보의 중대한 내용의 변경의 경우, 회사가 실시하는 변경된 정보에 대한 검수를 통과해야만 변경된 정보가 서비스에 반영됩니다. <br>
            </div><br><br>
            <div class="Sub">제 12조 (회원에 대한 통지)</div><br>
            <div>
                회사는 회원의 서비스 이용에 필요한 권리 및 의무 등에 관한 사항을 회원이 지정한 E-MAIL, SMS,SNS 등(회원가입시 회원이 회사에 통지한 방법)으로 통지할 수 있습니다. <br>
                회사는 불특정 다수 회원에 대한 통지의 경우 서비스에 게시함으로써 개별통지에 갈음할 수 있습니다.
            </div><br><br>
            <div class="Sub">제 13조 (계정의 관리)</div><br>
            <div>
                계정에 대한 관리책임은 회원에게 있으며, 회원은 계정을 타인에게 양도, 대여할 수 없습니다. <br>
                회사의 귀책사유에 의하지 아니한 계정의 유출, 양도, 대여로 인한 손실이나 손해에 대하여 회사는 아무런 책임을 지지 않습니다. <br>
                회원이 계정을 도난당하거나 제3자가 이를 사용하고 있음을 인지한 경우, 즉시 회사로 통보하여야 하고, 회사의 안내가 있는 경우에는 그에 따라야 합니다. <br>
            </div><br><br><br>
        </article>
        <article>
            <div class="Main"> &lt; 4장 서비스의 이용 &gt;</div><br><br>
            <div class="Sub">제 14조 (정보의 제공)</div><br>
            <div>
                회원은 회사의 자체 서비스 및 공지내용을 전자적 전송매체를 통해 받을 수 있으며, 광고 정보의 경우 수신 여부를 선택할 수 있습니다. 수신에 동의한 회원에 한하여 광고 정보를 받게 됩니다. 다만, 거래 관련 필수 정보 및 문의에 대한 회신 등의 주요 메세지는 제외됩니다.
            </div><br><br>
            <div class="Sub">제 15조 (회원의 게시물)</div><br>
            <div>
                각 프로젝트내에서 회원 간의 메모 혹은 게시내용은 프로젝트 내에서 글쓴이 공개를 원칙으로 하며,  관리자에 의해 초대되고 승인된 참여자는 관리자에 의해 삭제될 수 있으나, 참여자의 동의를 받아야 기 작성된 내용의 삭제 혹은 수정이 가능합니다. <br>
                작성물이 서비스에 의해 공개될 때 다음항에 해당하는 경우 그 게시물은 별도의 통지없이 삭제 혹은 강제 회원탈퇴될 수 있습니다.<br>
            </div><br>
            <div>
                A. 다른 회원 또는 제 3자를 비방하거나 중상모략으로 명예를 손상시키는 경우 <br>
                B. 공공질서 또는 미풍양속에 위배되는 내용인 경우(예: 욕설, 음란 등 ) <br>
                C. 범죄적 행위에 결부된다고 인정되는 내용일 경우(예: 자살모의, 도박, 절도 등 ) <br>
                D. 고의성에 관계없이 서비스에 위해를 가하거나 서비스의 건전한 운영을 저해하는 경우 <br>
                E. 기타 관계법령에 위반 되거나, 본 약관 제 9조에 해당하는 경우<br>
            </div><br><br>
            <div class="Sub">제 16조 (게시물의 관리)</div><br>
            <div>회사는 건전한 통신문화정착과 효율적인 서비스운영을 위하여 회원이 게시하거나 제공하는 자료가 다음에 해당한다고 판단되는 경우, 임으로 삭제, 적합한 위치로의 자료이동, 또는 등록거부를 할 수 있습니다.</div><br>
            <div>
                A. 회사에서 규정한 게시기간을 초과한 경우 <br>
                B. 게시된 내용의 성격이 게시판과 부합하지 않을 경우 <br> 
                C. 제3자 또는 회사의 명예, 신용을 훼손하거나 오용하는 내용 또는 영업을 방해하는 내용인 경우 <br>
                D. 범죄적 행위와 결부된다고 객관적으로 인정되는 내용인 경우 <br>
                E. 제3자 또는 회사의 지적재산권 등 권리를 침해하는 내용인 경우 <br>
                F. 정보통신망법 및 저작권법 등 관계법령 및 회사의 게시물 관리규정에 위배된다고 판단되는 경우<br>
            </div><br>
            <div>
                2.정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하”정보통신망법”이라 합니다.)의 규정에 의해 다른 회원의 공개된 게시물 등이 본인의 사생활을 침해하거나 명예를 훼손하는 등의 경우, 권리를 침해받은 회원 또는 제3자(이하 ”삭제 등 신청인”이라 합니다.)는 그 침해사실을 소명하여 회사에 해당 게시물 등의 삭제 또는 반박 내용의 게재를 요청할 수 있습니다. 이 경우 회사는 해당 게시물 등의 권리 침해 여부를 판단할 수 없거나 당사자 간의 다툼이 예상되는 경우, 해당 게시물 등에 대한 접근을 임시직으로 차단하는 조치(이하”임시조치”라 합니다.)를 최장 30일까지 취합니다.
            </div><br>
            <div>
                3.본 조에 따른 세부절차는 “정보통신망법” 및 “저작권법”이 규정한 범위 내에서 회사가 정한 내부규정에 따릅니다.
            </div><br>
            <div>
                4.”회원”이 등록한 자료로 인해 타인(도는 타법인)으로부터 허위사실공표, 명예훼손, 또는 불쾌감 조성 등으로 삭제 요청이 접수된 경우 “회사”는 “회원”에게 사전 통지 없이 본 자료를 삭제할 수 있으며 삭제 후 메일 등의 방법(회원가입시 회원이 회사에 통지한 방법)으로 통지할 수 있습니다.
            </div><br><br>
            <div class="Sub">제 17조 (권리의 귀속)</div><br>
            <div>
                1. 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다. 단 회원의 게시물 및 제휴계약에 따라 제공된 저작물 등은 제외합니다. <br><br>
                2. 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 콘텐츠 등을 이용할 수 있는 이용권만을 부여하며, 회원은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다. <br><br>
                3. 회원은 자신이 서비스내에 게시한 게시물(프로젝트내에서 진행된 권한설정된 내용 외에 공개승인된 영역(포트폴리오, 회사 프로필, 개인프로필 등)을 회사가 국내.외에서 다음 호의 목적으로 사용하는 것을 허락합니다. <br><br>
            </div>
            <div>
                A. 서비스(제3자가 운영하는 사이트 또는 미디어의 일정 영역 내에 입점하여 서비스가 제공되는 경우 포함)내에서 게시물의 크기를 변환하거나 단순화하는 등의 방식을 수정하는 것 <br>
                B. 회사 또는 관계사가 운영하는 본 서비스 및 연동서비스에 게시물을 복제. 전송. 전시하는 것. 다만 , 회원이 게시물의 복제.전송.전시에 반대 의견을 E-mail을 통해 관리자에게 통지할 경우에는 그러하지 않습니다. <br>
                C. 회사의 서비스를 홍보하기 위한 목적으로 미디어, 소셜미디어를 포함한 디지털 마케팅 채널, 통신사 등에게 게시물의 내용을 보도, 방영하게 하는 것.<br>
            </div><br>
            <div>
                4. 전 항의 규정에도 불구하고, 회사가 게시물을 전 항 각 호에 기재된 목적 이외에 제3자에게 게시물을 제공하고 금전적 대가를 지급받는 경우에는 사전에 전화, E-mail등의 방법(회원가입시 회원이 회사에 통지한 방법)으로 회원의 동의를 얻습니다. 이 경우 회사는 회원에게 별도의 보상을 제공합니다. 
                <br><br>
                5. 회원이 서비스에 게시물을 게재하는 것은 다른 회원이 게시물을 서비스 내에서 사용하거나, 회사가 검색결과로 사용하는 것을 허락한 것으로 봅니다. 그리고 스마트폰, 태블릿 PC의 서비스 이용자(앱 또는 브라우저로 서비스를 비가입 방문한 경우도 포함)가 소프트웨어(앱, 브라우저) 또는 하드웨어(스마트폰, 태블릿PC)에서 제공하는 기능을 이용하여 게시물을 저장한 후 활용하는 것을 허락한 것으로 봅니다. 
                <br><br>
                6. 제 21조에 의해 이용계약이 해지되는 경우 회원이 서비스에 게시한 게시물은 삭제됩니다. 다만, 다른 회원 또는 제3자에게 의하여 스크랩, 공유 등의 기능을 통해 다시 게시된 게시물, 댓글 등 다른 회원의 정상적인 서비스이용에 필요한 게시물은 삭제되지 않습니다. (예: 동일 프로젝트를 진행한 회원이 서비스를 계속 이용하고 있을 경우)
                <br><br>
                7. 회사는 서비스 운영정책상 또는 회사가 운영하는 사이트 간의 통합 등을 하는 경우 게시물의 내용을 변경하지 아니하고 게시물의 게재 위치를 변경. 이전하거나 사이트간 공유로 하여 서비스할 수 있습니다. 다만, 게시물의 이전. 변경 또는 공유를 하는 경우에는 사전 공지합니다.
            </div><br><br>
            <div class="Sub">제 18조 (서비스 이용료)</div><br>
            <div>
                1. 회사의 기본 서비스 방침은 회사의 사정이 허락하는 한 원칙적으로 무료 사용입니다. <br>
                2. 회사는 필요한 경우 서비스 이용료를 신설, 변경할 수 있으며, 신설 또는 변경사항은 어플리케이션 화면 및 공지사항을 통해 공지합니다. <br>
                3. 회사는 거래 활성화 등을 위해 서비스 이용료 범위 내에서 특정 거래에 대한 서비스 이용료를 할인할 수 있습니다. 또한 회사는 특정 회원에게 서비스 이용료를 회사가 정하는 일정한 기준과 절차에 따라 조정 또는 면제할 수 있습니다. <br>
            </div><br><br>
            <div class="Sub">제 19조 (서비스이용료 결제방식)</div><br>
            <div>
                회사의 필요에 따라 서비스의 이용료가 신설될 경우, 이용료 결제방식은 회사의 필요에 의해 선설, 변경될 수 있으며 신설 또는 변경사항은 어플리케이션 화면 및 공지사항을 통하여 공지됩니다. <br>
                이용료의 결제와 환불정책은 유료서비스 개시시점에 어플리케이션 화면 및 공지사항을 통하여 공지됩니다. <br>
            </div><br><br>
            <div class="Sub">제 20조 (회원 등급 및 기준)</div><br>
            <div>
                1. “회원” 일정조건의 조건 증빙을 통해 “프로젝트”를 생성할수 있으며,”매니져”로서 생성된 프로젝트에서 활동할수 있습니다. <br>
                2. “매니져”는 프로젝트내에 “부매니져”를 두어 프로젝트를 생성 및 관리 운영할 수 있으며, 직접 관리가 가능합니다. (공정에 따라 초대되는 회원에게 제공되는 정보는 차이가 있습니다.)<br>
                3. “매니져”는 회원을 초청하여 “전문가(각공정 전문가)”로 등급 산정하여 관리할 수 있습니다. <br>
                4. “작업자”로의 초청은 “부매니져” 이상 등급의 회원이 초청한 내용을 “회원”이 승인함 (받은 링크를 클릭하여 연결 )으로써 이루어집니다. <br>
                5. 이 등급의 제도는 프로젝트의 책임자, 공정의 전문가가 누구인가를 구분하는 기준으로 활용되며, 능력의 판별기준으로 사용되는 것은 아닙니다.  <br>
                6. 등급 기준의 변동은 제2조의 내용에 따라 고지합니다. 프로젝트내에서 상호 평가를 일정기준상에서 평가 진행 할 수 있습니다. <br>
            </div><br><br>
            <div class="Sub">제 21조 (평가제도)</div><br>
            <div>평가제도 도입시 약관에 정한 고지 방식에 따라 회원에게 고지 합니다. </div><br><br>
            <div class="Sub">제 22조 (서비스 이용 시 주의사항)</div><br>
            <div>
                프로젝트의 정보는 각 회원(디렉터, 매니져, 작업자, 회원 등)이 상호간의 신뢰감 고양과 효율적인 작업수행 및 관리를 위해 필요한 정보를 반드시 명기해야 합니다. ( 주소, 현장정보, 작업내용 등 ) <br>
                프로젝트내에서 각 회원의 정보는 상호 확인을 위해 회원간 정보를 확인할 수 있습니다. <br>
                프로젝트내에서 생기는 상호 거래에 따른 일방의 손해는 서비스 제공자인 데일리밀도(일의밀도, 회사)에서 책임지지 않습니다. <br>
                프로젝트내에서 생긴 용역거래의 상호거래 금액의 적정성에 대한 판단을 회사는 서비스 하지 않습니다. <br>
                회원은 회사에서 공식적으로 인정한 경우를 제외하고는 서비스를 이용한 상품을 판매하는 영업 활동을 할 수 없으며, 특히 해킹, 광고를 통한 수익, 음란사이트를 통한 상업행위, 상용소프트웨어 불법배포 등을 할 수 없습니다. 이를 위반하여 발생한 영업 활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 회사가 책임을 지지 않으며, 회원은 이와 같은 행위와 관련하여 회사에 대하여 손해배상 의무를 집니다. <br>
                회원은 서비스의 이용권한, 기타 서비스 이용계약상의 지위를 타인에게 양도, 증여, 대여, 또는 양도나 대여의 청약, 등을 할 수 없으며 이를 담보로 제공할 수 없습니다. <br>
                천재지변, 폭풍, 테러, 시민봉기 및 기타 일방의 합리적 지배범위 밖의 사유로 인해 상대방 및 제3자에게 가해진 손해에 대해 책임을 지지 아니합니다.<br>
            </div><br><br>
            <div class="Sub">제 23조 (서비스 이용제한)</div><br>
            <div>
                1. 회사는 일의밀도 어플리케이션을 통해 “회원” 간 효율적인 프로젝트 관리와 상호 신뢰성 확보를 위하여 이 약관에서 정한 바에 따라 회원자격을 정지하거나 서비스의 이용을 제한할 수 있습니다. 회원자격이 정지된 회원은 “일의밀도”서비스의 전체 또는 일부 이용이 제한됩니다. 
                <br><br>
                2. 회사는 아래와 같은 사항 적발 시 자격정지 또는 서비스이용을 제한할 수 있습니다.  <br>
            </div>
            <div style="padding-left: 50px;">
                A. 회원이 회원가입시 기재한 정보내용으로 여러차례 연락을 시도하였음데도 불구하고 일체의 응대가 진행되지 않을 경우 (허위정보, 허위 거래 지원) <br>
                B.  프로젝트 진행시 초대되어 참여하게 된 “회원”이 상호거래 합의 후 이행하지 않았을 때(서비스 사용이용 제한됩니다. )<br>
                C. 기업 등급으로서 프로젝트를 생성하고 회원을 초대하여 프로젝트를 진행할 시에 작업자 등급의 회원에게 합의된 비용이 특정한 사유없이 지급되지 않고 있음이 인지되었을 때 (서비스 이용이 제한되며, 다른 회원들의 열람시에 그 정보가 공개 됩니다.)<br>
                D. 프로젝트 공정의 확인 데이터에서 프로젝트와 무관한 데이터 혹은 미풍양속에 해가되는 등 제 9조 3항에 해당되는 경우 (서비스 이용에 제한을 받게 됩니다. )<br>
                E. 기타 위법 및 부당행위를 행하는 경우, 그 내용은 다음 항목 중에 하나에 해당할 경우를 말하며, 이 경우 타인의 권리보호, 사이트의 안전 확보 등을 위해 필요한 조치를 취할 수 있고 배상 청구 및 법령에 따라 소를 제가할 수 있습니다. <br>
            </div><br>
            <div>
                a. 스팸정보 전송 및 악성코드 프로그램 유포를 행하거나 범죄 및 불법행위에 서비스를 이용한 경우 <br>
                b. 타인의 개인정보를 부정 사용하여 회원으로 가입하거나 서비스를 이용한 경우 <br>
                c. 거짓된 정보를 입력하여 상호간 프로젝트의 원할한 진행을 방해할 경우 <br>
                d. 데이터의 정당한 권한 없이 스스로 또는 제3자를 통해 복사, 퍼가기, 스크래핑하거나 기타의 방법으로 취득하여 상업적으로 이용한 경우 <br>
                e. 이외에 약관 또는 법령에 위반하거나 타인의 권리를 침해하고, 상호 신뢰의 회복에 방해가 되는 행위, 또한 그러한 위법 및 부당한 행위가 있는 것으로 의심될 만한 상당한 이유가 있는 경우 <br>
                f. 비매너 행위(욕설, 비방, 모욕 등)에 따른 업무 방해 행위를 행하는 경우 <br>
            </div><br><br>
            <div class="Sub">제 24조 (의무사항 및 신의성실)</div><br>
            <div>
                회원은 상호 신의성실의 원칙에 입각하여 본계약을 성실히 이행하며 상대방의 명예 손실과 프로젝트의 손상이 가지 않도록 최선을 다하여야 합니다. <br>
                회원의 개인정보는 서비스 제공 이외의 용도로 사용할 수 없습니다.<br>
            </div><br><br>
            <div class="Sub">제 25조 (손해배상)</div><br>
            <div>
                회원 상호 함께 프로젝트를 진행중 고의 또는 과실로 상호협의된 사항을 불이행하거나 혹은 명백히 미진한 경우, 그로 인해 상대방에게 손해가 발생한 경우, 고의 또는 과실로 손해를 끼친 회원에게 그 모든 손해에 대해 배상을 청구 할 수 있습니다. <br>
                회원이 본 약관의 규정을 위반함으로 인해 회사에 손해가 발생하는 경우, 위반한 회원은 회사에 발생한 모든 손해를 배상해야 합니다. <br>
                회원이 본 서비스를 이용 도중 행한 불법행위나 약관 위반 행위로 인해 회사가 당해 회원 이외의 제 3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우, 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 합니다. 회사가 면책되지 못한 경우 당해 회원은 그로 인해 회사에 발생한 모든 손해를 배상해야 합니다.<br>               
            </div><br><br>
            <div class="Sub">제 26조 (면책사항)</div><br>
            <div>
                회사는 천재지변, 디도스(DIDOS)공격,IDC장애, 기간통신사업자의 회선 장애 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면책됩니다. <br>
                회사는 회원의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다. <br>
                회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임지지 않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다. 회사는 회원이 사이트에 게재한 게시물의 정확성 등 내용에 대하여는 책임을 지지 않습니다. <br>
                회사는 회원 상호가 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다. <br>
                회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서 회사는 일절 책임을 지지 않습니다. <br>
                게시물 또는 댓글 등을 통해 표현하거나 드러나는 회원의 견해와 회사의 견해는 무관하며, 회사는 회원이 제공하는 게시물 또는 댓글 등에 대하여 어떠한 책임도 부담하지 아니합니다. <br>
                본 서비스 화면에서 링크, 배너 등을 통하여 연결된 회사(이하 피연결회사)와 회원 간에 이루어진 거래에 회사는 개입하지 않으며, 해당 거래에 대하여 책임을 지지 않습니다. <br>
                회사는 회원의 게시물을 등록 전에 사전심사 하거나 상식적으로 게시물의 내용을 확인 또는 검토하여야 할 의무가 없으며, 그 결과에 대한 책임을 지지 않습니다. <br>
                회사는 회원의 컴퓨터 환경이나 회사의 관리 범위에 있지 아니한 보안 문제로 인하여 발생하는 제반문제 또는 현재의 보안기술 수준으로 방어가 곤란한 네트워크 해킹 등 회사의 귀책사유 없이 발생하는 문제에 대해서 책임을 지지 않습니다. <br>
                회사는 회원이 회원가입시에 알려준 연락방법 이외의 방법으로 회원에게 연락할 의무가 없으며, 회원은 회사에 대하여 연락을 받지 못했다고 주장할 수 없습니다.  다만, 회원이 회사에 연락방법의 변경을 알리고 회사에서 이를 승인하여 회원정보를 변경한 경우에는 그러하지 아니합니다.<br>
                회사는 회원들 사이의 거래(용역, 서비스, 물품공급, 도급 등 일체의 경우를 포함한다.)의 당사자나 보증인이 아니며, 회원은 하자, 수량부족, 미시공, 채무불이행, 기타 다른 회원과의 분쟁에 관하여 계약당사자간에 분쟁을 해결해야 합니다. 회원관리에 있어서 회사에 고의나 중대한 과실이 없는 한 회사는 회원들 사이의 거래에 대하여 어떠한 책임도 지지 아니합니다.<br>
            </div><br><br>
            <div class="Sub">제 27조 (분쟁의 처리 및 관할법원)</div><br>
            <div>
                서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의 합니다. <br>
                회사와 이용자 간에 제기된 소송에는 대한민국 법을 적용합니다. <br>
                본 약관은 한글로 작성되었습니다. 본 약관이 번역본이 한글 원문과 차이가 있을 경우 한글 원문이 우선합니다. <br>
                본 조 제1항의 협의에서도 분쟁이 해결되지 않아 소송이 제기되는 경우에는 “회사”의 본사 소재지를 관할하는 법원(또는 서울중앙지방법원)을 전속 관할법원으로 합니다.<br>
            </div><br><br>
            <div>
                준수사항 1. 회원의 권리와 책임 <br>
                데일리 밀도 서비스 어플리케이션인 일의 밀도 애플리케이션을 사용함에 있어 프로젝트내에서 이루어지는 거래에 대하여 회사는 어떠한 보증도 제공하지 않습니다. 또한 상호 거래행위에서 발생되는 손실에 대해서는 회사가 절대 책임을 부담하지 않으며, 모든 분쟁은 회원 간에 직접 해결하여야 합니다. 
                <br><br>
                준수사항2. 개인정보 도용금지 <br>
                모든 회원은 중개서비스와 관련하여 회사로부터 제공받는 정보를 그 제공목적 외의 다른 목적으로 사용하거나 타인에게 유출 또는 제공해서는 안되며, 그 위반으로 인한 모든 책임을 부담하여야 합니다. 또한, 회원은 자신의 개인정보를 책임 있게 관리하여 타인이 회원의 개인정보를 사용하여 중개서비스를 부정하게 이용하지 않도록 해야 합니다. 
                <br><br>
                준수사항3. 회원간 상호 의무 <br>
                회원간 상호 거래는 인테리어업의 상호 신뢰를 돈독하게 하기 위하여 상호 성실하게 프로젝트를 진행해야 한다. 
                <br><br>
                준수사항4. 일의 밀도 어플리케이션 (공사체크) 사용 조건 
            </div>
            <div>
                1. 회원은 이 애플리케이션이나 이 애플리케이션과 관련된 정보나 소프트웨어를 변경, 복사, 공개, 라이선스, 판매 또는 상업화 해서는 안됩니다. 이로 인한 손해에 대해서는 배상의 의무를 가집니다. <br>
                2. 이 애플리케이션에 대한 권리를 임대, 대여 또는 양도 해서는 안됩니다. <br>
                3. 어떤 식으로든 일의밀도 서비스에 손상을 끼치거나 타인의 사용을 방해하거나 간섭할 수 없습니다. <br>
                4. 회원은 모든 관련 법령을 준수하여 이 애플리케이션을 사용해야 합니다. <br>
                5. 사용조건을 위반시에는 사용권리는 즉시 종료 됩니다.<br>
            </div><br>
            <div>
                지적재산. 회사는 특허, 저작권, 영업 비밀, 상표 또는 부정경쟁방지법 상의 모든 권리를 포함한 애플리케이션에 대한 모든 권리, 타이틀과 이해관계, 그리고 모든 어플, 개정, 연장, 복구를 포함한 그 밖의 모든 재산권을 가지고 있거나 또는 사용권한을 부여 받고 있습니다. 회원은 변경, 적용, 번역, 파생작업 준비, 디컴파일, 리버스 엔지니어, 해체 또는 그 밖에 이 애플리케이션으로부터 소스코드를 끌어 내려고 해서는 안되며, 이 애플리케이션에 포함된 회사의 저작권 통지, 상표 또는 그 밖의 재산권 통지를 제거, 변경 또는 모호하게 해서는 안됩니다. 
                <br><br>
                보증 부인. 회사는 회원이 이 애플리케이션을 사용한 결과로 입은 피해에 대해 책임을 지지 않습니다. 이 애플리케이션과 이 애플리케이션으로 이용하는 서비스는 “있는 그대로” 그리고 “이용 가능한 상태로” 제공됩니다. 회사는 상품성, 특정 목적 부합성, 재산권 비 침해성, 그리고 이 애플리케이션의 보안, 신뢰, 시기적절, 성능 등을 포함하나 이에 한정되지 않는 모든 명시적, 묵시적, 법령상의 보증을 법령이 허용하는 최대 범위에서 명시적으로 부인합니다. 이 애플리케이션을 사용함으로써 회원의 하드웨어 장비에 생기는 피해나 데이터 손실에 대해 회원이 전적으로 책임을 부담합니다. 이 애플리케이션의 Bid 업데이트와 그 밖의 통지 기능은 실시간으로 발생하지 않을 수 있습니다. 그런 기능은 회원의 물리적인 장소나 회원의 무선 데이터 서비스 제공자의 네트웤에서 기인하는 지연이나 대기 시간 등을 포함하나 이에 한정되지 않는 회사의 통제를 벗어난 지연이 있을 경우 그에 따릅니다.
                <br><br>
                책임 제한. 회사는 이 애플리케이션의 사용 또는 부정 사용에 대해 회원이나 애플리케이션 사용자에게 책임을 지지 않습니다. 이러한 책임 제한은 (가) 그런 클레임이 보증, 계약, 불법행위 또는 그 밖의 어떤 원인을 근거로 하든, (회사가 그런 피해의 가능성을 고지 받았다 하더라도) 직접, 간접, 우연, 부수적, 특별, 예시, 징벌적 피해를 포함하며 (나) 이 애플리케이션의 사용 또는 부정 사용, 신뢰 또는 이 애플리케이션의 중단, 중지, 종료로부터 피해가 발생한 경우(제3자가 야기한 피해 포함)에도 적용되며 (다) 제한된 치유책의 본질적인 목적의 실패에도 불구하고 법이 허용하는 최대 범위에서 적용됩니다. 그리고, 회원은 결과적인 손실 이윤, 특별, 간접, 부수적 손해를 회사로부터 회복하려고 하지 않습니다. 어떤 지역은 손해의 제외나 보증의 부인을 허용하지 않으므로 그런 제외와 부인은 회원에게 적용되지 않을 수도 있습니다.
                <br><br>
                면책. 회원은 제3자가 회원의 행위나 회원의 이 애플리케이션의 사용 그리고 이 개인정보보호 정책과 사용 조건, 관련 법률과 규정의 위반과 직간접적으로 관련하여 제기하는 클레임으로부터 회사나 그 모회사, 자회사, 책임자, 임원, 주주, 에이전트, 직원을 면책시키고 피해가 없도록 해야 합니다. 회사는 회원의 면책 의무를 근거로 관련 사안의 방어와 통제를 회사의 비용으로 떠맡을 수 있는 권리를 보유하지만, 그렇게 하는 것이 회원의 면책 의무를 면제해 주는 것은 아닙니다.
                <br><br>
                이 애플리케이션 또는 사용 조건의 변경. 회사는 때때로 이 애플리케이션이나 본 사용 조건을 변경할 권리를 가지고 있습니다. 중요한 변경은 새로운 사용자에 대해 즉시 효력을 발생하고 기존 사용자에 대해서는 통지 후 30일 경과 후에 효력을 발생합니다. 회원이 변경에 동의하지 않으면 이 애플리케이션을 삭제하고 이용을 중단하십시오. 30일 통지 기간 이후에 이 애플리케이션을 사용하는 것은 그러한 변경에 동의 하였음을 의미합니다.
            </div><br><br>
            <div class="Sub">추가 조항</div><br>
            <div>
                애플리케이션이 설치되는 모바일 기기에 따라 회원에게 적용되는 추가 조항입니다. <br>
                iOS - Apple<br>
                •  이 사용 조건은 회원과 회사 사이의 합의이지 회원과 애플사와의 합의가 아닙니다. 애플사는 애플리케이션과 그 내용에 대하여 책임을 지지 않습니다.<br>
                •  회사는 회원이 소유하거나 관리하는 iOS 제품에서만 그리고 앱 스토어 서비스 조건에 규정된 사용 규칙이 허용하는 대로만 애플리케이션을 사용할 권리를 회원에게 부여합니다.<br>
                •  애플사는 애플리케이션과 관련하여 그 어떠한 유지 보수 서비스를 제공할 의무가 없습니다.<br>
                •  애플사는 그 어떤 제3자 지적 재산 침해 클레임의 조사, 방어, 화해, 면제에 대하여 책임을 지지 않습니다.<br>
                •  애플사는 애플리케이션 또는 애플리케이션의 소유 및/또는 사용과 관련하여 (a) 제조물 책임 클레임, (b) 애플리케이션이 법률 또는 규제 요구사항을 준수하지 못한다는 클레임, (c) 소비자 보호 또는 유사한 법령 상의 클레임 등등 회원이나 제3자의 클레임을 취급할 책임이 없습니다.<br>
                •  애플리케이션이 적용 가능한 품질보증을 준수하지 못한 경우, 회원은 애플사에 통지할 수 있고, 애플사는 회원에게 애플리케이션 구매 금액을 환불해 줄 것이며, 법률이 허용하는 최대 한도 내에서 애플사는 애플리케이션과 관련하여 그 어떤 보증 의무도 부담하지 않습니다.<br>
                •  애플과 애플의 자회사는 이 사용 조건의 제3자 수혜자이며, 회원이 이 사용 조건을 수용하면 제3자 수혜자로서의 애플사는 회원에게 이 사용 조건을 실행할 권리를 가지게 됩니다(그리고, 그런 권리를 수용한 것으로 간주됩니다)<br>
            </div>
        </article>
    </section>
    </body>
  </div>
</template>

<script>
import HeaDer from '../Header/HeaDer.vue'
export default{
  name : 'ServicePage',
  components: {
    HeaDer,
  }
}
</script>

<style>
.Serviceinner{
    background: gray;
    color: white;
    padding: 50px;
    text-align: left;
    padding-left: 5%;
    padding-bottom: 15%;
}
</style>
