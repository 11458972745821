<template>
  <div class="sectorInfo">
      <div class="FirstSector">
        <div style="width: 80%; margin: 0 auto; padding-top: 30px;">
          <Logo/>
        </div>
        <div class="section1">
            <div style="float:left">
              <div class="textBox">
                <div class="FirstSector_text1">멋지게 일하는 인테리어인</div>
                <div class="FirstSector_text2">공사체크</div>
              </div>
              <div class="FirstSectorImg1">
                <img class="HeaderLogo" src="../../../assets/1.png">
              </div>
              <div class="textBox2">
                <div class="FirstSector_text3"> 한손에 담은 인테리어</div>
                <div class="FirstSector_text4">프로젝트 내용을 한곳에 담아 현장을 체크, 기록, 공유합니다.</div>
              </div>
          </div>
          <div style="float:right">
            <div>
              <img class="smp" width="500" src="../../../assets/phone.png">
              <div class="BadgesContainer">
                <a href="https://apps.apple.com/us/app/%EB%84%A4%EC%9D%B4%EB%B2%84-naver/id393499958?itsct=apps_box_badge&amp;itscg=30200"  style="display: inline-block; overflow: hidden; border-radius: 13px; width: 250px; height: 95px; padding-right:50px"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1286409600" alt="Download on the App Store" style="border-radius: 13px; width: 250px; height: 83px;"></a>
                <a href='https://play.google.com/store/apps/details?id=com.nhn.android.search&hl=ko&gl=US&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='다운로드하기 Google Play' width=275px; src='https://play.google.com/intl/en_us/badges/static/images/badges/ko_badge_web_generic.png'/></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="secondSector">
        <div style="padding-top:70px"></div>
        <div class="section2">
          <div style="float:left;">
            <div class="textBox">
              <div class="secondSector_text1"> 
                <img src="../../../assets/2.png">
                스마트 커뮤니케이션
                <div style="font-size:20px; font-weight: normal; padding-left:92px">
                  담당자 실시간 초대 및 배치를 합니다. <br>
                  전체 현장 내용을 안내합니다. <br>
                  공정별 분리된 업무 대화를 합니다 <br>
                </div>
              </div>
            </div>
          </div>
          <div style="float:right">
            <div style="position: relative;">
              <img style="padding-right:400px" class="smp" src="../../../assets/phone.png">
                <div style="position: absolute; top:50px; right:520px">
                    <img style="width: 500px; height: auto;" src="../../../assets/C4.png">
                </div>
                <div style="position: absolute; top:10px; right:110px">
                    <img style="width: 500px; height: auto;" src="../../../assets/C3.png">
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="thirdSector">
        <div style="padding-top:70px"></div>
        <div class="section2">
          <div style="float:left;">
            <div class="textBox">
              <div class="thirdText1"> 
                <img src="../../../assets/2.png">
                한눈에 모든 프로젝트
                <div style="font-size:20px; font-weight: normal; padding-left:92px">
                  캘린더와 연계된 전체 일정을 파악합니다. <br>
                  오늘 일정에 대한 브리핑과 알람설정을 합니다. <br>
                  다수의 프로젝트를 한눈에 확인합니다. <br>
                </div>
              </div>
            </div>
          </div>
          <div style="float:right">
            <div style="position: relative;">
              <img style="padding-right:400px" class="smp" src="../../../assets/phone.png">
                <div style="position: absolute; top:10px; right:160px">
                    <img style="width: 500px; height: auto;" src="../../../assets/C6.png">
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fourSector">
        <div style="padding-top:70px"></div>
        <div class="section2">
          <div style="float:left;">
            <div class="textBox">
              <div class="secondSector_text1"> 
                <img src="../../../assets/2.png">
                한번의 기록, 정리
                <div style="font-size:20px; font-weight: normal; padding-left:92px">
                  공정별 업무 내용 정리가 됩니다. <br>
                  시간별 진행 내용이 기록 됩니다. <br>
                  필요한 데이터 현장별로 분류됩니다. <br>
                  등급별 정보 노출범위를 조절 할 수 있습니다.<br>
                </div>
              </div>
            </div>
          </div>
          <div style="float:right">
            <div style="position: relative;">
              <img style="padding-right:400px" class="smp" src="../../../assets/phone.png">
              <div style="position: absolute; top:-100px; right:100px">
                    <img style="width: 550px; height: auto;" src="../../../assets/C7.png">
                </div>
            </div>
          </div>
        </div>
      </div>

    <body style="background-color: #141829;"> 

      <div class="fiveSector">
        <div style="padding-top:70px"></div>
        <div class="section2">
          <div style="float:left;">
            <div class="textBox">
              <div class="secondSector_text1"> 
                <img src="../../../assets/2.png">
                프로젝트 매니지먼트
                <div style="font-size:20px; font-weight: normal; padding-left:92px">
                  공정별 업무 내용 전달되고 기록됩니다. <br>
                  수신, 발신인이 명확한 커뮤니케이션이 됩니다. <br>
                  검수 요청 및 확인 기능으로 명확한 작업확인이 가능합니다. <br>
                </div>
              </div>
            </div>
          </div>
          <div style="float:right">
            <div style="position: relative;">
              <img style="padding-right:400px" class="smp" src="../../../assets/phone.png">
                <div style="position: absolute; top:150px; right:550px">
                    <img style="width: 500px; height: auto;" src="../../../assets/C1.png">
                </div>
                <div style="position: absolute; top:150px; right:180px">
                    <img style="width: 500px; height: auto;" src="../../../assets/C5.png">
                </div>
            </div>
          </div>
        </div>
        <div style="padding-top:950px"></div>
        <div class="section2">
          <div style="float:left;">
            <div class="textBox">
              <div class="thirdText1"> 
                <img src="../../../assets/2.png">
                포트폴리오 관리
                <div style="font-size:20px; font-weight: normal; padding-left:92px">
                  현장 전체를 마무리 하며, 기록을 만듭니다. <br>
                  회사내의 전체 포트폴리오로 공유 할수 있습니다. <br>
                  회사의 포트폴리오와 개인이 참여한 항목을 분리하여 생성됩니다. <br>
                  현장 참여 개인의 이력 정리합니다. <br>
                </div>
              </div>
            </div>
          </div>
          <div style="float:right">
            <div style="position: relative;">
              <img style="padding-right:400px" class="smp" src="../../../assets/phone.png">
              <div style="position: absolute; top:300px; right:100px">
                    <img style="width: 500px; height: auto;" src="../../../assets/C2.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="lastSector">
        <div style="padding-top:70px"></div>
        <div class="section2">
          <div style="float:left;">
            <div class="textBox" style="position: relative; top:-250px">
              <div class="thirdText1"> 
                <img src="../../../assets/2.png">
                앱 사용 예시
              </div>
              <div style="position: absolute;">
                <img style="width: 500px; height: 400px;" src="../../../assets/white.png">
              </div>
              <div style="position: absolute; right: -650px">
                <img style="width: 500px; height: 400px;" src="../../../assets/white.png">
              </div>
              <div style="position: absolute; right: -1200px">
                <img style="width: 500px; height: 400px;" src="../../../assets/white.png">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <FooTer/>
      </div>

    </body>
  </div>
</template>

<script>
import FooTer from '../Footer/FooTer.vue'
import Logo from '../Logo/LoGo.vue'
export default{
  name : 'LandingPage',
  components: {
    Logo,
    FooTer,
  }
}
</script>

<style>
*{
  margin:0;
  padding:0;
}

.body{
  margin:unset;
}

.section1{
  width: 1600px;
  margin: 0 auto;
}

.section2{
  width:1600px;
  margin: 0 auto;
}
.FirstSector{
  background: linear-gradient(169.91deg, #45507E 7.3%, #9EBDDE 65.14%, #8FA2E2 76.23%);
  border-bottom-right-radius: 5em;
  height:850px;
}
.secondSector{
  background: white;
  height:740px;
}
.thirdSector{
  background: linear-gradient(172.5deg, #FDFDFD 6.13%, #9EBDDE 49.81%, #8FA2E2 91.44%);
  border: 1px solid white; 
  border-bottom-right-radius: 5em;
  height:740px;
}
.fourSector{
  background: white;
  border-bottom-right-radius: 5em;
  height:740px;
}
.fiveSector{
  background: linear-gradient(172.22deg, #FFFFFF 6.26%, #9EBDDE 42.05%, #45507E 65.32%);
  border-bottom-right-radius: 5em;
  height:1700px;
}
.lastSector{
  background-color: #141829;
  margin-bottom: 600px;
}

.textBox{
  text-align: left;;
  padding-top: 50px;
}
.textBox2{
  text-align: left;;
  padding-top: 1%;
}
.FirstSector_text1{
  color:white;
  font-weight: bold;
  font-size:55px
}
/* Tablet */
@media screen and (max-width:768px) {
  .FirstSector_text1{
  color:white;
  font-weight: bold;
  font-size:23px
}
}
/* phone */
@media screen and (max-width:640px) {
  .FirstSector_text1{
  color:white;
  font-weight: bold;
  font-size:20px
}
}

.secondSector_text1{
  color:black;
  font-weight: bold;
  font-size:55px;
  padding-top:240px;
  position: relative;
}
.thirdText1{
  color:white;
  font-weight: bold;
  font-size:55px;
  padding-top:240px;
}

.FirstSector_text2{
  color:skyblue;
  font-size:75px
}
/* Tablet */
@media screen and (max-width:768px) {
  .FirstSector_text2{
  color:skyblue;
  font-size:40px
}
}
/* phone */
@media screen and (max-width:640px) {
  .FirstSector_text2{
  color:skyblue;
  font-size:30px
}
}
.FirstSectorImg1{
  text-align: left;
  padding-top: 6%;
}

.FirstSector_text3{
  color:white;
  font-weight: bold;
  font-size: 38px;
}
.FirstSector_text4{
  color:white;
  padding-top:1%;
}

.smp{
  width: 400px;
  height: auto;

}

.StoreBadges1{
 padding-top:1px
}


</style>
